export enum CoreIcon {
  message = 'message',
  plus = 'plus',
  copy = 'copy',
  trash = 'trash',
  refresh = 'refresh',
  close = 'close',
  arrowUp = 'arrow-up',
  arrowDown = 'arrow-down',
  arrowRight = 'arrow-right',
  pencil = 'pencil',
  user = 'user',
}
